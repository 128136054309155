/** @format */

import React, { useState } from "react";
import { Doughnut } from "react-chartjs-2";
import { ArcElement, Chart as ChartJS, Tooltip, Legend } from "chart.js";
import "bootstrap/dist/css/bootstrap.min.css";
import "./DocStat.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";

ChartJS.register(ArcElement, Tooltip, Legend);

const LotStat = ({ lots }) => {
  const [isCollapsed, setIsCollapsed] = useState(true);

  const toggleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };
  const statusCounts = lots.reduce((acc, lot) => {
    const status = lot.sStatus;
    acc[status] = (acc[status] || 0) + 1;
    return acc;
  }, {});

  const options = {
    plugins: {
      legend: {
        position: "right",
      },
    },
  };

  const chartData = {
    labels: Object.keys(statusCounts),
    datasets: [
      {
        label: "Répartition des statuts",
        data: Object.values(statusCounts),
        backgroundColor: [
          "rgba(255, 99, 132, 0.2)",
          "rgba(54, 162, 235, 0.2)",
          "rgba(255, 206, 86, 0.2)",
          "rgba(75, 192, 192, 0.2)",
          "rgba(153, 102, 255, 0.2)",
          "rgba(255, 159, 64, 0.2)",
        ],
        borderColor: [
          "rgba(255, 99, 132, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
          "rgba(75, 192, 192, 1)",
          "rgba(153, 102, 255, 1)",
          "rgba(255, 159, 64, 1)",
        ],
        borderWidth: 1,
      },
    ],
  };

  const stats = lots.reduce((acc, lot) => {
    const { iCreatedDocs = 0, iCompletedDocs = 0} = lot;

    acc.totalDocuments += iCreatedDocs;    
    acc.realisesDocuments += iCompletedDocs;

    if (lot.sStatus === 'Completed') {
      acc.realisesLots = (acc.realisesLots || 0) + 1;      
    }
    return acc;
  }, {
    totalDocuments: 0, realisesDocuments: 0,    
    realisesLots: 0,
  });

  return (
    <div className="container mt-4 collapsible-section" style={{ width: '610px' }}>
      <div className="titre-stat" onClick={toggleCollapse}>
        <p style={{ fontWeight:'bold', fontSize:'18px', paddingTop:'10px'}}>Statistiques</p>
        <FontAwesomeIcon icon={isCollapsed ? faChevronDown : faChevronUp} />
      </div>
      {!isCollapsed && (
      <div className="row ">
        <div className="col-md-6">
          <div className="chart-container" style={{ width: '250px', height: '180px', background:'#FFFFFF' }}>
            <Doughnut data={chartData} options={options} />
          </div>
        </div>
        <div className="col-md-6">
          <div className="table-responsive" style={{width: '290px', height: '180px',lineHeight:'0.6',borderRadius:'5px'}}>
            <table className="table table-striped">
              <thead>
                <tr>
                  <th>Catégories</th>
                  <th>Total</th>
                  <th>Réalisés</th>
                </tr>
              </thead>
              <tbody>
                <tr><td>Lots</td><td>{lots.length}</td><td>{stats.realisesLots}</td></tr>
                <tr><td>Documents</td><td>{stats.totalDocuments}</td><td>{stats.realisesDocuments}</td></tr>             
              </tbody>
            </table>
          </div>
        </div>
      </div>
         )}
    </div>
  );
};

export default LotStat;
