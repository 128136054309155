/** @format */

import React, { useState, useEffect } from "react";
import LotStat from "../LotStat/LotStat";
import Papa from "papaparse";
import Cookies from "js-cookie";
import { getInstance } from "../../../../API/DocQuery";
import DatePicker from "react-datepicker";
import { updateInstanceData } from "../../../../API/updateInstance";
import NavBar from "../../../Navbar/Navbar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { Link } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { setFilters, setFilterField } from "../../../../Redux/filtersSlice";
import {
  faSortUp,
  faSortDown,
  faFileCsv,
  faSearch,
  faCopy,
  faLink,
} from "@fortawesome/free-solid-svg-icons";
import { getModel } from "../../../../API/modelList";
import "react-datepicker/dist/react-datepicker.css";
import { getDoc } from "../../../../API/DocList";
import config from "./config.json"; // Charger la configuration

const Plan01Lots = () => {
  const savedFilters = JSON.parse(localStorage.getItem("filters")) || {
    sInstanceName: "",
    sStatus: "",
    sFinalCustomer: "",
    dCreationDate: null,
    dDateRemise: null,
    iCreatedDocs: null,
    iNbTotalPlans: null,
    iNbTotalComplexe: null,
    iNbTotalMedium: null,
    iNbTotalSimple: null,
    iNbTotalOCR: null,
  };
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [filter, setFilter] = useState(savedFilters);
  const [sortField, setSortField] = useState("dCreationDate");
  const [sortOrder, setSortOrder] = useState("desc"); // 'asc' ou 'desc'
  const [uniqueStatuses, setUniqueStatuses] = useState([]);
  const [modelInstanceStatuses, setModelInstanceStatuses] = useState([]);
  const [ModelName, setModelName] = useState([]);
  const [dateRangeFilter, setDateRangeFilter] = useState({
    fromDate: null,
    toDate: null,
    filterType: "between",
  });
  const itemsPerPage = 10;
  const navigate = useNavigate();
  const [configData, setConfigData] = useState({});
  const [isDateFilterVisible, setIsDateFilterVisible] = useState(false);

  const handleCopy = (DocId) => {
    toast.success(`ID copié: ${DocId}`, {
      autoClose: 2000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  useEffect(() => {
    // Sauvegarder les filtres dans le localStorage
    localStorage.setItem("filters", JSON.stringify(filter));
  }, [filter]);

  const formatCustomDate = (dateString) => {
    const date = parseCustomDate(dateString);
    const options = { year: "numeric", month: "2-digit", day: "2-digit" };
    return (
      date.toLocaleDateString("fr-FR", options) +
      " " +
      date.toLocaleTimeString("fr-FR")
    );
  };

  useEffect(() => {
    const idToken = Cookies.get("Token"); // Récupérer le token depuis les cookies
    const model = Cookies.get("modelId");
    const loadAndProcessData = async () => {
      try {
        const responseData = await getInstance(idToken, model);
        const loadModelData = async () => {
          const modelData = await getModel(idToken); // Supposons que ceci retourne directement le tableau
          setModelName(modelData[0].ModelName);
          if (modelData && modelData.length > 0) {
            const instanceStatuses = modelData[0].ModelInstanceStatus;
            console.log("Statuts chargés: ", instanceStatuses);
            setModelInstanceStatuses(instanceStatuses);
          }
        };
        loadModelData();
        setConfigData(config);
        if (Array.isArray(responseData)) {
          setData(responseData);
          const loadedStatuses = responseData.map((item) => item.sStatus);
          setUniqueStatuses([...new Set(loadedStatuses)]);
        } else {
          console.error(
            "Les données chargées ne sont pas un tableau",
            responseData
          );
          setData([]);
        }
      } catch (error) {
        console.error("Erreur lors du chargement des données", error);
      }
    };

    loadAndProcessData();
  }, []);

  //tri par defaut par date de creation decroissante
  // Fonction pour trier les données
  const sortData = (data, field, order) => {
    return data.sort((a, b) => {
      const dateA = parseCustomDate(a[field]);
      const dateB = parseCustomDate(b[field]);
      return order === "asc" ? dateA - dateB : dateB - dateA;
    });
  };

  // Utiliser useEffect pour trier les données une fois que le composant est monté
  useEffect(() => {
    setData((prevData) => sortData([...prevData], sortField, sortOrder));
  }, [sortField, sortOrder]);

  useEffect(() => {
    setConfigData(config);
  }, []);

  const handleSubmitChange = async (newdata,instanceid) => {    
    const updatedItem = newdata.find((item) => item.kInstanceId === instanceid);
    console.log("UpdatedItem",updatedItem);
    if (updatedItem) {
      const { dDateRemise, sStatus } = updatedItem; // Extraction des valeurs à envoyer
      console.log(dDateRemise, sStatus, instanceid);
      const idToken = Cookies.get("Token"); // Extraction du token
      try {
        const response = await updateInstanceData(
          idToken,
          instanceid,
          sStatus,
          dDateRemise
        );
        console.log("Réponse de la mise à jour: ", response);
        //alert ok
        //toast.success("Instance mise à jour avec succès!");
      } catch (error) {
        toast.error("Erreur lors de la mise à jour de l'instance");
        console.error("Erreur lors de la mise à jour des données", error);
      }
    }
  };

  const formatDateForStorage = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");
    console.log("New Date is",`${year}${month}${day}-${hours}${minutes}${seconds}`);
    return `${year}${month}${day}-${hours}${minutes}${seconds}`;
  };
  useEffect(() => {
    // Charger les filtres sauvegardés depuis le localStorage
    const savedFilters = JSON.parse(localStorage.getItem("filters"));
    if (savedFilters) {
      setFilter(savedFilters);
    }
  }, []);

  useEffect(() => {
    // Sauvegarder les filtres dans le localStorage
    localStorage.setItem("filters", JSON.stringify(filter));
  }, [filter]);

  const applyFilters = (data) => {
    return data
      .filter((item) =>
        filter.sInstanceName
          ? item.sInstanceName &&
            item.sInstanceName
              .toLowerCase()
              .includes(filter.sInstanceName.toLowerCase())
          : true
      )
      .filter((item) =>
        filter.sStatus ? item.sStatus === filter.sStatus : true
      )
      .filter((item) =>
        filter.sFinalCustomer
          ? item.sFinalCustomer &&
            item.sFinalCustomer
              .toLowerCase()
              .includes(filter.sFinalCustomer.toLowerCase())
          : true
      )
      .filter((item) => {
        if (!dateRangeFilter.fromDate && !dateRangeFilter.toDate) return true;

        const creationDate = parseCustomDate(item.dCreationDate);
        const remiseDate = parseCustomDate(item.dDateRemise);

        if (dateRangeFilter.fromDate && dateRangeFilter.toDate) {
          return (
            (creationDate >= dateRangeFilter.fromDate &&
              creationDate <= dateRangeFilter.toDate) ||
            (remiseDate >= dateRangeFilter.fromDate &&
              remiseDate <= dateRangeFilter.toDate)
          );
        }

        return true;
      });
  };
  const handleSaveFilters = () => {
    localStorage.setItem("filters", JSON.stringify(filter));
  };
  
  const parseCustomDate = (dateStr) => {
    if (!dateStr || typeof dateStr !== "string" || dateStr.length !== 15) {
      console.error("Invalid date string:", dateStr);
      return new Date(); // Retourne une date par défaut en cas d'erreur
    }
    const year = parseInt(dateStr.slice(0, 4), 10);
    const month = parseInt(dateStr.slice(4, 6), 10) - 1; // Les mois commencent à 0 en JavaScript
    const day = parseInt(dateStr.slice(6, 8), 10);
    const hours = parseInt(dateStr.slice(9, 11), 10);
    const minutes = parseInt(dateStr.slice(11, 13), 10);
    const seconds = parseInt(dateStr.slice(13, 15), 10);
    return new Date(year, month, day, hours, minutes, seconds);
  };

  const parseRemiseDate = (dateStr) => {    
    console.log("Date Str : ",dateStr);
    if (dateStr && typeof dateStr == "string" && dateStr.length > 10) {
      const year = String(parseInt(dateStr.slice(0, 4), 10));
      const month = String(parseInt(dateStr.slice(4, 6), 10) ).padStart(2,'0');  // Les mois commencent à 0 en JavaScript
      const day = String(parseInt(dateStr.slice(6, 8), 10)).padStart(2,'0');
      return year+'-'+month+'-'+day;
    } else {
      return "";
    }
  };

  const handleInputChange = (value, kInstanceId, key) => {
    console.log('event=',value);
    const newData = data.map((item) => {
      if (item.kInstanceId == kInstanceId) {
        return {
          ...item,
          [key]:
            key == "dDateRemise"
              ? formatDateForStorage(new Date(value))
              : value,
        };
      }
      return item;
    });
    setData(newData);
    handleSubmitChange(newData,kInstanceId);
  };

  const toggleVisibility = () => {
    setIsDateFilterVisible(!isDateFilterVisible);
  };

  const applySort = (filteredData) => {
    if (!sortField) return filteredData;
    return filteredData.sort((a, b) => {
      const extractDate = (dateStr) => dateStr.split("-")[0];
      if (["dCreationDate", "dDateRemise"].includes(sortField)) {
        const dateA = extractDate(a[sortField]);
        const dateB = extractDate(b[sortField]);
        // Comparaison des chaînes de date YYYYMMDD
        return sortOrder === "asc"
          ? dateA.localeCompare(dateB)
          : dateB.localeCompare(dateA);
      }
      // Gestion des nombres
      else if (["iCreatedDocs", "iNbTotalPlans"].includes(sortField)) {
        const numA = a[sortField] ? parseInt(a[sortField], 10) : 0;
        const numB = b[sortField] ? parseInt(b[sortField], 10) : 0;
        return sortOrder === "asc" ? numA - numB : numB - numA;
      } else if (
        [
          "iNbTotalComplexe",
          "iNbTotalMedium",
          "iNbTotalSimple",
          "iNbTotalOCR",
        ].includes(sortField)
      ) {
        const numA = a[sortField] ? parseInt(a[sortField], 10) : 0;
        const numB = b[sortField] ? parseInt(b[sortField], 10) : 0;
        return sortOrder === "asc" ? numA - numB : numB - numA;
      }
      // Gestion des autres champs (chaînes de caractères)
      else {
        const strA = a[sortField] ? a[sortField].toLowerCase() : "";
        const strB = b[sortField] ? b[sortField].toLowerCase() : "";
        return sortOrder === "asc"
          ? strA.localeCompare(strB)
          : strB.localeCompare(strA);
      }
    });
  };

  const handleDownloadCSV = () => {
    const csvData = Papa.unparse(data);
    const blob = new Blob([csvData], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.setAttribute("href", url);
    link.setAttribute("download", "instances.csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleDownloadCSVDoc = async (instanceId) => {
    try {
      const idToken = Cookies.get("Token"); // Extraction du token

      // Appel à l'API pour obtenir les données
      const data = await getDoc(idToken, instanceId);

      // Conversion des données en CSV
      const csvData = Papa.unparse(data);

      // Création du blob et de l'URL pour le téléchargement
      const blob = new Blob([csvData], { type: "text/csv;charset=utf-8;" });
      const url = URL.createObjectURL(blob);

      // Création du lien et déclenchement du téléchargement
      const link = document.createElement("a");
      link.setAttribute("href", url);
      link.setAttribute("download", "allDocs.csv");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error("Erreur lors du téléchargement du fichier CSV :", error);
    }
  };
  const toggleSort = (field) => {
    if (sortField === field) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      setSortField(field);
      setSortOrder("asc");
    }
  };

  return (
    <>
      <NavBar />
      <div className='page-container' style={{ backgroundColor: "#F7F9F9" }}>
        <h6 style={{ textAlign: "left", marginTop: "10px" }}>
          <Link to='/User/'>All models</Link>
          {" > "}
          {ModelName} {" > "}Instances
        </h6>
        <LotStat lots={applySort(applyFilters(data))} />

        {isDateFilterVisible && (
          <div style={configData.styles.DateFilter}>
            <table style={{width:'300px'}}>
              <tr>
                <td style={{width:'100px',textAlign:'left'}}>            
                  <label>Date de début :</label>
                </td>
                <td style={{width:'100px'}}>            
                  <DatePicker
                    selected={dateRangeFilter.fromDate}
                    onChange={(date) =>
                      setDateRangeFilter({ ...dateRangeFilter, fromDate: date })
                    }
                    dateFormat='yyyy-MM-dd'
                    className='form-control'
                  />
                </td>
              </tr>
              <tr>
              <td style={{width:'100px',textAlign:'left'}}>            
                  <label>Date de fin :</label>
                </td>
                <td style={{width:'100px'}}>            
                  <DatePicker
                    selected={dateRangeFilter.toDate}
                    onChange={(date) =>
                      setDateRangeFilter({ ...dateRangeFilter, toDate: date })
                    }
                    dateFormat='yyyy-MM-dd'
                    className='form-control'
                  />
                </td>
              </tr>
              <tr>
                <td colSpan="2" style={{height:'50px',alignItems:'center', justifyContent:'center'}}><center><button onClick={toggleVisibility}>Fermer</button></center></td>                
              </tr>
            </table>            
          </div>
        )}

        <div
          className='table-responsive'
          style={{
            padding: "20px",
            borderRadius: "5px",
            overflowX: "auto",
            lineHeight: "0.7",
          }}
        >
          <table className='table table-striped'>
            <thead>
              <tr style={{ backgroundColor: "#183152", textAlign: "center", verticalAlign: "middle" }}>
                <th style={{ width: "100px" }}>
                  Actions 
                </th>
                <th style={{ width: "300px" }} colSpan='2'>
                  Nom 
                </th>
                <th style={{ width: "100px" }}>
                  <label onClick={toggleVisibility}>Création</label>                  
                </th>
                <th style={{ width: "120px" }}>
                  Remise                  
                </th>
                <th style={{ width: "100px" }}>
                  Statut                  
                </th>
                {//<th style={{ width: "200px" }}>
                  //Client final
                //</th>
                }
                <th style={{ width: "100px" }}>
                  Docs Attendus                  
                </th>
                <th style={{ width: "100px" }}>
                  Docs créés                  
                </th>
                <th style={{ width: "100px" }}>
                  Total plans                  
                </th>
                <th style={{ width: "100px" }}>
                  Plans complexes                  
                </th>
                <th style={{ width: "100px" }}>
                  Plans medium                  
                </th>
                <th style={{ width: "100px" }}>
                  Plans simples                  
                </th>
                <th style={{ width: "100px" }}>
                  Docs OCR                  
                </th>
                <th style={{ width: "100px" }}>
                  Invalides
                </th>
              </tr>
              
              <tr style={{backgroundColor: "#a6c9e0", textAlign: "center", verticalAlign: "middle"}}>
                <td></td>
                <td style={{width:"300px"}}>                  
                  <input
                    type='text'
                    name='name'
                    placeholder='Nom'                   
                    //className='form-control me-2 filter-input' // Ajout de la classe filter-input
                    value={filter.sInstanceName}
                    onChange={(e) =>
                      setFilter({ ...filter, sInstanceName: e.target.value })
                    }
                    style={{backgroundColor: "#a6c9e0", textAlign: "center", width: "200px" }}
                  />
                </td>
                <td></td>
                <td style={{ width: "100px" }} onClick={() => toggleSort("dCreationDate")}>
                  {sortField === "dCreationDate" && sortOrder === "asc" ? (
                    <FontAwesomeIcon icon={faSortDown} style={{ marginLeft: "5px" }}/>
                  ) : (
                    <FontAwesomeIcon icon={faSortUp} style={{ marginLeft: "5px" }}/>
                  )}
                </td>
                <td style={{ width: "100px" }} onClick={() => toggleSort("dDateRemise")}>                
                  {sortField === "dDateRemise" && sortOrder === "asc" ? (
                    <FontAwesomeIcon icon={faSortDown} style={{ marginLeft: "5px" }}/>
                  ) : (
                    <FontAwesomeIcon icon={faSortUp} style={{ marginLeft: "5px" }}/>
                  )}
                </td>
                <td>
                  <select
                    className='form-select filter-select'
                    onChange={(e) =>
                      setFilter({ ...filter, sStatus: e.target.value })
                    }
                    style={{ backgroundColor: "#a6c9e0", textAlign: "center", fontSize: "12px", height: "auto", width: "100px" }}
                  >
                    <option value=''>Status</option>
                    {uniqueStatuses.map((status) => (
                      <option key={status} value={status}>
                        {status}
                      </option>
                    ))}
                  </select>
                </td>
                {/*
                <td>
                  <input
                    type='text'
                    placeholder='Client final'
                    className='form-control me-2 filter-input'
                    onChange={(e) =>
                      setFilter({ ...filter, sFinalCustomer: e.target.value })
                    }
                    style={{ backgroundColor: "#a6c9e0", textAlign: "center", width: "190px" }}
                  />
                </td>
                */}
                <td onClick={() => toggleSort("iExpectedDocs")}>                
                  {sortField === "iCreatedDocs" && sortOrder === "asc" ? (
                    <FontAwesomeIcon icon={faSortDown} style={{ marginLeft: "5px" }}/>
                  ) : (
                    <FontAwesomeIcon icon={faSortUp} style={{ marginLeft: "5px" }}/>
                  )}
                </td>
                <td onClick={() => toggleSort("iCreatedDocs")}>                
                  {sortField === "iCreatedDocs" && sortOrder === "asc" ? (
                    <FontAwesomeIcon icon={faSortDown} style={{ marginLeft: "5px" }}/>
                  ) : (
                    <FontAwesomeIcon icon={faSortUp} style={{ marginLeft: "5px" }}/>
                  )}
                </td>
                <td onClick={() => toggleSort("iNbTotalPlans")}>                
                  {sortField === "iNbTotalPlans" && sortOrder === "asc" ? (
                    <FontAwesomeIcon icon={faSortDown} style={{ marginLeft: "5px" }}/>
                  ) : (
                    <FontAwesomeIcon icon={faSortUp} style={{ marginLeft: "5px" }}/>
                  )}                  
                </td>
                <td onClick={() => toggleSort("iNbTotalComplexe")}>                
                  {sortField === "iNbTotalComplexe" && sortOrder === "asc" ? (
                    <FontAwesomeIcon icon={faSortDown} style={{ marginLeft: "5px" }}/>
                  ) : (
                    <FontAwesomeIcon icon={faSortUp} style={{ marginLeft: "5px" }}/>
                  )}
                </td>
                <td onClick={() => toggleSort("iNbTotalMedium")}>                
                  {sortField === "iNbTotalMedium" && sortOrder === "asc" ? (
                    <FontAwesomeIcon icon={faSortDown} style={{ marginLeft: "5px" }}/>
                  ) : (
                    <FontAwesomeIcon icon={faSortUp} style={{ marginLeft: "5px" }}/>
                  )}
                </td>
                <td onClick={() => toggleSort("iNbTotalSimple")}>                
                  {sortField === "iNbTotalSimple" && sortOrder === "asc" ? (
                    <FontAwesomeIcon icon={faSortDown} style={{ marginLeft: "5px" }}/>
                  ) : (
                    <FontAwesomeIcon icon={faSortUp} style={{ marginLeft: "5px" }}/>
                  )}
                </td>
                <td onClick={() => toggleSort("iNbTotalOCR")}>                
                  {sortField === "iNbTotalOCR" && sortOrder === "asc" ? (
                    <FontAwesomeIcon icon={faSortDown} style={{ marginLeft: "5px" }}/>
                  ) : (
                    <FontAwesomeIcon icon={faSortUp} style={{ marginLeft: "5px" }}/>
                  )}
                </td>
                <td onClick={() => toggleSort("iInvalidDocs")}>                
                  {sortField === "iInvalidDocs" && sortOrder === "asc" ? (
                    <FontAwesomeIcon icon={faSortDown} style={{ marginLeft: "5px" }}/>
                  ) : (
                    <FontAwesomeIcon icon={faSortUp} style={{ marginLeft: "5px" }}/>
                  )}
                </td>
              </tr>

            </thead>

            <tbody>


              {applySort(applyFilters(data))
                .slice(
                  (currentPage - 1) * itemsPerPage,
                  currentPage * itemsPerPage
                )
                .map((item) => (
                  <tr
                    key={item.kInstanceId}
                    style={{ textAlign: "center", verticalAlign: "middle" }}
                  >
                    <td
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >                     
                      <button
                        className='btn btn-outline-primary btn-sm'
                        onClick={() => handleDownloadCSVDoc(item.kInstanceId)}
                      >
                        <FontAwesomeIcon icon={faFileCsv} />
                      </button>
                      <Link
                        to={`/User/Instances/${item.kInstanceId}`}
                        className='btn btn-outline-primary btn-sm'
                      >
                        <FontAwesomeIcon icon={faLink} />
                      </Link>
                      <button
                        className='btn btn-outline-secondary'
                        onClick={() => {
                          // Définir un cookie
                          Cookies.set("instanceId", item.kInstanceId); // Ajout d'une expiration (facultatif)
                          Cookies.set("instanceName", item.sInstanceName);
                          navigate(`/User/Plan01Docs`);
                        }}
                      >
                        <FontAwesomeIcon icon={faSearch} />
                      </button>
                    </td>

                    <td style={{ textAlign: "center", verticalAlign: "middle" }}>
                      <span
                        style={{
                          fontSize: "12px",
                          marginLeft: "10px",
                          fontWeight: "bold",
                        }}
                      >
                        {item.sInstanceName}
                      </span>
                    </td>
                    <td style={{ width: "20px"}}>
                      <CopyToClipboard text={item.kInstanceId} onCopy={() => handleCopy(item.kInstanceId)}>
                        <button className='btn btn-outline-primary btn-sm'>
                          <FontAwesomeIcon icon={faCopy} />
                        </button>
                      </CopyToClipboard>
                    </td>
                    <td style={{ textAlign: "center", verticalAlign: "middle" }}>
                      {formatCustomDate(item.dCreationDate)}
                    </td>

                    <td style={{ textAlign: "center", verticalAlign: "middle" }}>                    
                      <DatePicker
                        selected={parseRemiseDate(item.dDateRemise)}
                        onChange={(date) => handleInputChange(date, item.kInstanceId, "dDateRemise")}
                        //onBlur={() => handleSubmitChange(item.kInstanceId)}
                      />                        
                    </td>
                    <td style={{ textAlign: "center", verticalAlign: "middle" }}>                      
                      <select
                        className='form-control '
                        style={{
                          fontSize: "12px",
                          height: "auto", // Permet au `<select>` de s'ajuster à la taille du texte
                        }} // Ajustez la taille selon vos besoins
                        value={item.sStatus || ""}
                        onChange={(e) => handleInputChange(e.target.value, item.kInstanceId, "sStatus")}
                        //onBlur={() => handleSubmitChange(item.kInstanceId)}
                      >
                        <option value={item.sStatus}>{item.sStatus}</option>
                        {modelInstanceStatuses.map((status, index) => (
                          <option key={index} value={status}>
                            {status}
                          </option>
                        ))}
                      </select>
                    </td>
                    {/*
                    <td style={{ textAlign: "center", verticalAlign: "middle" }}>
                      {item.sFinalCustomer}
                    </td>
                    */}
                    <td style={{ textAlign: "center", verticalAlign: "middle" }}>
                      {item.iExpectedDocs}
                    </td>
                    <td style={{ textAlign: "center", verticalAlign: "middle" }}>
                      {item.iCreatedDocs}
                    </td>
                    <td style={{ textAlign: "center", verticalAlign: "middle" }}>
                      {item.iNbTotalPlans}
                    </td>
                    <td style={{ textAlign: "center", verticalAlign: "middle" }}>
                      {item.iNbTotalComplexe}
                    </td>
                    <td style={{ textAlign: "center", verticalAlign: "middle" }}>
                      {item.iNbTotalMedium}
                    </td>
                    <td style={{ textAlign: "center", verticalAlign: "middle" }}>
                      {item.iNbTotalSimple}
                    </td>
                    <td style={{ textAlign: "center", verticalAlign: "middle" }}>
                      {item.iNbTotalOCR}
                    </td>
                    <td style={{ textAlign: "center", verticalAlign: "middle" }}>
                      {item.iInvalidDocs}
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            padding: "10px",
          }}
        >
          <div>
            <button
              className='btn btn-outline-primary btn-sm'
              onClick={handleDownloadCSV}
            >
              <FontAwesomeIcon icon={faFileCsv} /> Télécharger CSV
            </button>
          </div>
          <div>
            {Array.from(
              { length: Math.ceil(data.length / itemsPerPage) },
              (_, index) => (
                <button
                  key={index}
                  className='btn btn-outline-primary btn-sm me-2'
                  onClick={() => setCurrentPage(index + 1)}
                  style={{
                    fontWeight: currentPage === index + 1 ? "bold" : "normal",
                  }}
                >
                  {index + 1}
                </button>
              )
            )}
          </div>
          <div className='col-md-3'>
            <button className='btn btn-primary' onClick={handleSaveFilters}>
              Sauvegarder les filtres
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Plan01Lots;
