import React from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import RouterDefault from './Routes/RouterDefault';
import RouterUser from './Routes/RouterUser';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import DisplayDocument from './Component/PDFWebsite/PDFWebsit';
import { Provider } from 'react-redux';
import store from './Redux/store';
function App() {
  return (
    <Provider store={store}>

    <Router>
      <div className='App'>
      <ToastContainer />
        <Routes>
          <Route path='/*' element={<RouterDefault />} />
          <Route path='/PDF' element={<DisplayDocument />} />
          <Route
            path='/User/*'
            element={
                <RouterUser />
            }
          />
        </Routes>
      </div>
    </Router>
    </Provider>

  );
}

export default App;
