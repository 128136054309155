// filtersSlice.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  sInstanceName: '',
  sStatus: '',
  sFinalCustomer: '',
  dCreationDate: null,
  dDateRemise: null,
};

const filtersSlice = createSlice({
  name: 'filters',
  initialState,
  reducers: {
    setFilters(state, action) {
      return { ...state, ...action.payload };
    },
    setFilterField(state, action) {
      state[action.payload.name] = action.payload.value;
    },
  },
});

export const { setFilters, setFilterField } = filtersSlice.actions;
export default filtersSlice.reducer;
