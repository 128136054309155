/** @format */

import React, { useState, useEffect } from "react";

import Cookies from "js-cookie";
import { getDoc } from "../../../../API/DocListPlans";

import NavBar from "../../../Navbar/Navbar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./doc.css";
import { docView } from "../../../../API/DocView";
import {
  faSortUp,
  faSortDown,
  faLink,
  faCopy,
  faFileAlt,
  faUpload,
} from "@fortawesome/free-solid-svg-icons";
import { getModel } from "../../../../API/modelList";
//import { getAsanaUserList } from "../../../../API/asanaUserList"; // In cae we need it that's a good start...
import "react-datepicker/dist/react-datepicker.css";
import { docUpdate } from "../../../../API/docUpdate";
import DocStat from "../../../Stats/DocStats";
import Modal from "react-modal";
import { attachZip } from "../../../../API/attachZip";
import { docAttachment } from "../../../../API/docAttachment";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { Link } from "react-router-dom";
import config from "./config.json"; // Charger la configuration

const Plan01Doc = () => {
  const [modelInstanceStatuses, setModelInstanceStatuses] = useState([]);
  const [data, setData] = useState([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [fileExtension, setFileExtension] = useState(""); //pr recuperer l'extension des pieces jointes
  const [selectedDocuments, setSelectedDocuments] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [filter, setFilter] = useState({});
  const [sortField, setSortField] = useState(null);
  const [sortOrder, setSortOrder] = useState("asc");
  const [uniqueStatuses, setUniqueStatuses] = useState([]);
  const [uniqueSorties, setUniqueSorties] = useState([]);
  const [ModelName, setModelName] = useState([]);
  const [hover, setHover] = useState(false);
  const [selectedDocumentId, setSelectedDocumentId] = useState(null);
  const [modelId, setModelId] = useState(); // Model ID [TODO: Get from API
  const [itemsPerPage, setItemPerPage] = useState(10);
  const navigate = useNavigate();
  const instanceId = Cookies.get("instanceId");
  const instanceName = Cookies.get("instanceName");
  const [configData, setConfigData] = useState({});



  useEffect(() => {
    const idToken = Cookies.get("Token");
    const loadModelData = async () => {
      const modelData = await getModel(idToken);
      setModelName(modelData[0].ModelName);
      setModelId(modelData[0].ModelId);
      const instanceStatuses = modelData[0].ModelDocumentStatus;
      console.log("Statuts chargés: ", instanceStatuses);
      setModelInstanceStatuses(instanceStatuses);
    };

    console.log("modelIdd", modelId);
    const loadAndProcessData = async () => {
      try {
        const responseData = await getDoc(idToken, instanceId);
        console.log("Données chargées: ", responseData);
        loadModelData();
        if (Array.isArray(responseData)) {
          setData(responseData);
          const loadedStatuses = responseData.map((item) => item.Status);
          const loadedSorties = responseData.map((item) => item.sortie);
          setUniqueStatuses([...new Set(loadedStatuses)]);
          setUniqueSorties([...new Set(loadedSorties)]);
          setConfigData(config);
        } else {
          console.error(
            "Les données chargées ne sont pas un tableau",
            responseData
          );
          setData([]);
        }
      } catch (error) {
        console.error("Erreur lors du chargement des données", error);
      }
    };

    loadAndProcessData();
  }, []);


  useEffect(() => {
    setConfigData(config);
  }, []);

  const handleCheckboxChange = (event, DocId) => {
    const isChecked = event.target.checked;
    if (isChecked) {
      setSelectedDocuments([...selectedDocuments, DocId]);
    } else {
      setSelectedDocuments(selectedDocuments.filter((id) => id !== DocId));
    }
  };

  const handleUpdateSelected = async () => {
    var errcount = 0;
    for (const DocId of selectedDocuments) {
      try {
        await handleSubmitChange(DocId);
      } catch (error) {
        errcount++;
      }
    }

    // Check if there were some errors ?
    if (errcount !== 0) {
      toast.error(
        `${errcount} erreur(s) lors de la mise à jour des documents !`,
        {
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
    } else {
      // Notifier l'utilisateur que la mise à jour est réussie
      toast.success("Données mises à jour avec succès !", {
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      // Réinitialiser les documents sélectionnés après la mise à jour
      setSelectedDocuments([]);
      // Reload page
      window.location.reload();
    }
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setSelectedFile(file);
      const fileName = file.name;
      const fileExt = fileName.substring(fileName.lastIndexOf(".") + 1);
      setFileExtension(fileExt);
      console.log("File extension is", fileExt);
    }
  };
  const handleItemsPerPageChange = (event) => {
    setItemPerPage(Number(event.target.value));
  };
  const handleUpload = async () => {
    if (!selectedFile) {
      // Vérifier si un fichier est sélectionné
      //alert("Veuillez sélectionner un fichier à télécharger.");
      const notifyFileSelection = () => {
        toast.warn("Veuillez sélectionner un fichier.", {
          autoClose: false, // Le toast ne se ferme pas automatiquement
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      };
      notifyFileSelection();
      return;
    }
    const idToken = Cookies.get("Token");

    try {
      // Appeler la fonction attachZip avec les paramètres nécessaires
      await attachZip(
        instanceId, // ID de l'instance
        selectedDocumentId, // ID du document
        modelId, // ID du modèle
        selectedFile, // Fichier sélectionné
        idToken, // Jeton d'authentification
        fileExtension
      );

      toast.success("Fichier chargé avec succès !");
      window.location.reload();
      closeModal();
    } catch (error) {
      // Gérer les erreurs lors du téléchargement du fichier
      console.error("Erreur lors du téléchargement du fichier :", error);
      //alert("Erreur lors du téléchargement du fichier : " + error.message);//c'est pas un upload??
      const notifyFileErrorUpload = (errorMessage) => {
        toast.error(`Erreur lors du chargement du fichier : ${errorMessage}`, {
          autoClose: false, // Le toast ne se ferme pas automatiquement
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      };
      notifyFileErrorUpload(error.message);
    }
  };

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };
  const handleCopy = (DocId) => {
    toast.success(`ID copié: ${DocId}`, {
      autoClose: 2000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const handleInputChange = (event, DocId, key) => {
    const { value, type, checked } = event.target;

    if (type === "checkbox") {
      if (checked) {
        setSelectedDocuments((prevSelected) => [...prevSelected, DocId]);
      } else {
        setSelectedDocuments((prevSelected) =>
          prevSelected.filter((id) => id !== DocId)
        );
      }
    } else {
      const newValue =
        key === "isvalid" ? event.target.value === "true" : value;
      const newData = data.map((item) => {
        if (item.DocId === DocId) {
          const updatedItem = { ...item, [key]: newValue };
          const isAnyFieldModified = Object.keys(updatedItem).some(
            (field) => field !== "DocId" && item[field] !== updatedItem[field]
          );
          // Update the checkbox based on the modification
          if (isAnyFieldModified) {
            // If at least one modifiable field is modified, check the checkbox
            setSelectedDocuments((prevSelected) => [...prevSelected, DocId]);
          } else {
            // If no modifiable field is modified, remove the checkbox
            setSelectedDocuments((prevSelected) =>
              prevSelected.filter((id) => id !== DocId)
            );
          }
          return updatedItem;
        }
        return item;
      });
      // Update the state with the modified data
      setData(newData);
    }
  };

  const handleSubmitChange = async (DocId) => {
    const updatedItem = data.find((item) => item.DocId === DocId);
    console.log("UpdatedItem",updatedItem);
    if (updatedItem) {
      const { Complexite, Priorite, isvalid } = updatedItem;
      const idToken = Cookies.get("Token");
      const newstatus = (isvalid==false) ? "invalid" : "qualified";
      console.log("new status is ",newstatus);
      try {
        const response = await docUpdate(
          idToken,
          instanceId,
          DocId,
          Complexite,
          Priorite,
          isvalid,
          newstatus
        );
        console.log("Mise à jour réussie", response);
        window.location.reload();
      } catch (error) {
        console.error("Erreur lors de la mise à jour des données", error);
      }
    }
  };

  const applyFilters = (data) => {

    {configData.columns && configData.columns.map(
      (column, index) =>
      data = data.filter((item) =>
        filter[column.Value]
          ? item[column.Value].toLowerCase().includes(filter[column.Value].toLowerCase())
          : true
    ))}

    return data
      .filter((item) =>
        filter.FileName
          ? item.FileName.toLowerCase().includes(filter.FileName.toLowerCase())
          : true
      )
      .filter((item) =>
        filter.Status
          ? item.Status.toLowerCase().includes(filter.Status.toLowerCase())
          : true
      )
      .filter((item) =>
        filter.Complexite
          ? item.Complexite.toLowerCase().includes(
              filter.Complexite.toLowerCase()
            )
          : true
      )
      .filter((item) =>
        filter.isvalid
          ? item.isvalid === (filter.isvalid === "true" ? true : false)
          : true
      )
  };

  const applySort = (filteredData) => {
    if (!sortField) return filteredData;
    return filteredData.sort((a, b) => {
      if (sortField === "LastUpdate") {
        const dateA = new Date(a[sortField]);
        const dateB = new Date(b[sortField]);
        return sortOrder === "asc" ? dateA - dateB : dateB - dateA;
      } else if (["Batiment", "Etage", "TypePlan"].includes(sortField)) {
        const valueA = a[sortField] ? a[sortField].toString() : "";
        const valueB = b[sortField] ? b[sortField].toString() : "";
        return sortOrder === "asc"
          ? valueA.localeCompare(valueB)
          : valueB.localeCompare(valueA);
      } else {
        return 0;
      }
    });
  };

  const toggleSort = (field) => {
    if (sortField === field) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      setSortField(field);
      setSortOrder("asc");
    }
  };
  
  const FilterChange = (key,value) => {
    setFilter({ ...filter, [key]: value })
  }

  const callGetModel = (docId, modelId) => {
    const token = Cookies.get("Token");
    const instanceId = Cookies.get("instanceId");
    docView(token, docId, modelId, instanceId);
  };

  const formatDate = (dateStr) => {
    if (!dateStr || dateStr.length < 8) return "";
    const year = dateStr.substring(0, 4);
    const month = dateStr.substring(4, 6);
    const day = dateStr.substring(6, 8);
    return `${day}-${month}-${year}`;
  };
  const handleDownload = async (docId, fileType) => {
    try {
      const token = Cookies.get("Token");
      await docAttachment(token, instanceId, docId, fileType, modelId);

      console.log("Téléchargement réussi !");
    } catch (error) {
      console.error("Erreur lors du téléchargement :", error);
    }
  };
  const pageCount = Math.ceil(data.length / itemsPerPage);

  return (
    <>
      <NavBar />
      <div className='page-container' style={{ backgroundColor: "#F7F9F9" }}>
        <h6 style={{ textAlign: "left", marginTop: "10px" }}>
          {" "}
          <Link to='/User/'>All models</Link> {" > "}
          {ModelName} {" > "}
          <span
            style={{
              cursor: "pointer",
              textDecoration: hover ? "underline" : "none",
            }}
            onClick={() => navigate("/User/plan01Lots")}
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
          >
            {instanceName}
          </span>
          {" > "} Documents
        </h6>
        <DocStat lots={applySort(applyFilters(data))} />
        <div
          className='table-responsive'
          style={{
            padding: "10px",
            borderRadius: "5px",
            overflowX: "auto",
            lineHeight: "0.5",
          }}
        >

          
          <div style={{ height: "500px", overflowY: "auto" }}>
          <table className='table-striped' style={{ tableLayout: "fixed", width: "100%" }}>
  
  
  {configData && configData.styles && configData.columns && (
  <thead style={{ position: "sticky", top: 0, zIndex: 2 }}>
    <tr style={configData.styles.th}>
     
      <th rowSpan='2' style={{ width: "150px" }}>Actions</th>
      <th style={{width:"250px"}}>Nom du document</th>
      <th style={{width:"50px", textAlign: "center"}}></th>
      <th style={{ width: "80px" }}>Statut</th>
      <th style={{ width: "150px" }}>Complexité</th>
      <th style={{width:"60px"}}>Validité</th>
      <th style={{ width: "100px" }}>MàJ</th>
      
      {configData.columns.map(
        (column, index) =>
        <th key={index} style={{width:column.size}}>{column.Name}</th>
      )}
    </tr>
  </thead>
  )}
  <tbody style={{ overflowY: "auto" }}>

  {configData && configData.styles && configData.columns && (
    <tr style={{ height: "25px", verticalAlign: "middle", position: "sticky", top: "27px", backgroundColor: "#a6c9e0", textAlign: "center", zIndex: 2 }}>
        
        <td style={{ width: "150px"}}>
          <input
            type='checkbox'
            checked={selectedDocuments.length === data.length}
            onChange={(e) => {
              if (e.target.checked) {
                setSelectedDocuments(data.map((item) => item.DocId));
              } else {
                setSelectedDocuments([]);
              }
            }}
          />
        </td>

        <td
          style={{
            width: "250px",
            backgroundColor: "#a6c9e0",
            verticalAlign: "center",
            textAlign: "center",
          }}          
        >
          <center>
            <input
              type='text'
              //className='form-control me-2 filter-input'
              onChange={(e) =>
                setFilter({ ...filter, FileName: e.target.value })
              }
              style={{
                width: "240px",
                fontSize: "14px",
                height: "25px",
              }}
            />
          </center>
        </td>
        <td></td>

        <td style={{ backgroundColor: "#a6c9e0", width: "80px" }}>          
          <select
            className='form-control'
            onChange={(e) =>
              setFilter({ ...filter, Status: e.target.value })
            }
            style={{
              textAlign: "center",
              fontSize: "14px",
              height: "25px",
              width: "100%",
              padding: "0px",
              verticalAlign: "middle",
            }}
          >
            <option value=''>Tous</option>
            {uniqueStatuses.map((status) => (
              <option key={status} value={status}>
                {status}
              </option>
            ))}
          </select>
        </td>
      
        <td style={{ backgroundColor: "#a6c9e0", width: "80px" }}>
          <select
            className='form-control'
            onChange={(e) =>
              setFilter({ ...filter, Complexite: e.target.value })
            }
            style={{
              textAlign: "center",
              fontSize: "14px",
              height: "25px",
              width: "100%",
              padding: "0px",
              verticalAlign: "middle",
            }}
          >
            <option value=''>Tous</option>
            <option value='Simple'>Simple</option>
            <option value='Moyen'>Moyen</option>
            <option value='Complexe'>Complexe</option>
          </select>
        </td>
    
        <td style={{ backgroundColor: "#a6c9e0", width: "70px" }}>
          <select
            className='form-control'
            onChange={(e) =>
              setFilter({ ...filter, isvalid: e.target.value })
            }
            style={{
              textAlign: "center",
              fontSize: "14px",
              height: "25px",
              width: "100%",
              padding: "0px",
              verticalAlign: "middle",
            }}
          >
            <option value=''>Tous</option>
            <option value='true'>Vrai</option>
            <option value='false'>Faux</option>
          </select>
        </td>
  
        <td style={{ width: "100px", backgroundColor: "#a6c9e0" }}>
          {sortField === "LastUpdate" && sortOrder === "asc" ? (
            <FontAwesomeIcon icon={faSortDown} style={{ marginLeft: "5px" }} />
          ) : (
            <FontAwesomeIcon icon={faSortUp} style={{ marginLeft: "5px" }} />
          )}
        </td>
     
      {configData.columns.map(
        (column, index) =>
        <td key={index} style={{width:column.size}}>
          <center>
          {column.Filter == "input" ? (
          <input
            type='text'
            placeholder={column.Name}
            //className='form-control me-2 filter-input'
            onChange={(e) => FilterChange(column.Value, e.target.value)}
            style={{
              width: "98%",
              fontSize: "14px",
              height: "25px",
              textAlign: "center"
            }}
          />
        ) : column.Filter == "select" ? (
          <select
            className='form-control'
            onChange={(e) => FilterChange(column.Value, e.target.value)}
            style={{
              textAlign: "center",
              fontSize: "14px",
              height: "25px",
              width: "100%",
              padding: "0px",
              verticalAlign: "middle",
            }}
          >
            <option value=''>Tous</option>
            {uniqueStatuses.map((status) => (
              <option key={status} value={status}>
                {status}
              </option>
            ))}
          </select>
        ) : null}
          </center>
        </td>
      )}

    </tr>
    )}

    {applySort(applyFilters(data))
      .slice(
        (currentPage - 1) * itemsPerPage,
        currentPage * itemsPerPage
      )
      .map((item) => (
        <tr key={item.DocId} style={{ fontSize: "12px", verticalAlign: "middle" }}>

            <td
              style={{
                align: "center",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              }}
            >
              {item.Attachmeents &&
                item.Attachmeents.length > 0 &&
                item.Attachmeents.filter(
                  (extension) =>
                    extension !== "" && extension !== null
                ).map((extension, index) => (
                  <button
                    title='download attachement'
                    style={{
                      marginRight: "3px",
                      textAlign: "center",
                      height: "25px",
                      padding: "1px",
                    }}
                    key={index}
                    className='btn btn-primary'
                    onClick={() =>
                      handleDownload(item.DocId, extension)
                    }
                  >
                    {extension}
                  </button>
                ))}
              <button
                title='upload attachement'
                style={{
                  marginRight: "3px",
                  textAlign: "center",
                  height: "25px",
                  padding: "1px",
                }}
                className='btn btn-primary'
                onClick={() => {
                  openModal();
                  setSelectedDocumentId(item.DocId);
                }}
              >
                <FontAwesomeIcon
                  icon={faUpload}
                  style={{ marginRight: "5px" }}
                />
              </button>
              <button
                title='view document'
                style={{
                  marginRight: "3px",
                  textAlign: "center",
                  height: "25px",
                  padding: "1px",
                }}
                className='btn btn-primary'
                onClick={() =>
                  callGetModel(item.DocId, item.ModelId)
                }
              >
                <FontAwesomeIcon
                  icon={faFileAlt}
                  style={{ marginRight: "5px" }}
                />
              </button>
              {item.Asana !== "" && (
                <button
                  title='view task on Asana'
                  style={{
                    marginRight: "3px",
                    textAlign: "center",
                    height: "25px",
                    padding: "1px",
                  }}
                  className='btn btn-outline-secondary'
                  onClick={() =>
                    window.open(item.Asana.permalink, "_blank")
                  }
                >
                  <FontAwesomeIcon icon={faLink} />
                </button>
              )}
              <input
                type='checkbox'
                checked={selectedDocuments.includes(item.DocId)}
                onChange={(e) =>
                  handleCheckboxChange(e, item.DocId)
                } // Gérer les changements de checkbox
              />
            </td>
            <td
              style={{
                width:"250px",
                textAlign: "center",
                overflow: "auto",
                textOverflow: "ellipsis",
                //whiteSpace: "nowrap",
              }}
              title={item.DocId}
            >
             {item.FileName}
            </td>
          <td
            title='copy'            
            style={{
              align: "center",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap"
            }}
          >
            <CopyToClipboard text={item.DocId} onCopy={() => handleCopy(item.DocId)}>
               <button>
                    <FontAwesomeIcon icon={faCopy}/>
                </button>
            </CopyToClipboard>
          </td>
          
          <td
              style={{
                align: "center",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              }}
            >
              {item.Status}
            </td>
          
            <td>
            {item.Status!="invalid" && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                {["Simple", "Moyen", "Complexe"].map(
                  (complexite, index) => {
                    const colors = ["green", "orange", "red"];
                    const isSelected =
                      item.Complexite === complexite;
                    const baseColor = colors[index];
                    const backgroundColor = isSelected
                      ? baseColor
                      : "#f0f0f0";
                    const color = isSelected ? "white" : baseColor;

                    return (
                      <button
                        key={complexite}
                        style={{
                          backgroundColor,
                          color,
                          border: `1px solid ${baseColor}`,
                          padding: "5px 10px",
                          margin: "0 5px",
                          cursor: "pointer",
                          borderRadius: "5px",
                        }}
                        onClick={() =>
                          handleInputChange(
                            { target: { value: complexite } },
                            item.DocId,
                            "Complexite"
                          )
                        }
                      >
                        {complexite[0]}
                      </button>
                    );
                  }
                )}
              </div>
            )}
            { (item.Status=="invalid") && 
              <div> {item.Complexite} </div>
            }
            </td>

            <td
              style={{
                align: "center",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              }}
            >
              {(item.Status!="invalid") &&
              <select
                className='form-control'
                value={
                  item.isvalid !== undefined
                    ? item.isvalid
                      ? "true"
                      : "false"
                    : ""
                }
                onChange={(e) =>
                  handleInputChange(e, item.DocId, "isvalid")
                }
                style={{
                  width: "100%",
                  textAlign: "center",
                  height: "25px",
                  fontSize: "12px",
                  padding: "1px",
                  color: item.isvalid ? "#006400" : "#8B0000",
                  backgroundColor: item.isvalid
                    ? "#90EE90"
                    : "#FFA07A",
                }}
              >
                <option value=''>Valide</option>
                <option value='true'>Vrai</option>
                <option value='false'>Faux</option>
              </select>
              }
              { (item.Status=="invalid") && 
                <div> {item.isvalid ? "Vrai" : "Faux"} </div>
              }
            </td>
          
            <td
              style={{
                align: "center",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              }}
            >
              {formatDate(item.LastUpdate)}
            </td>
          
        {configData.columns.map(
          (column, index) =>
            <td
              style={{
                width:"100%",
                align: "center",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              }}
            >
              {item[column.Value]}
            </td>
        )}
        </tr>
      ))}
              </tbody>
            </table>
          </div>
        </div>
        <div></div>
        <div className='pagination d-flex justify-content-center'>
          <button
            type='button'
            className='btn btn-primary'
            onClick={() => {
              const filteredDocuments = applySort(applyFilters(data));

              const documentsJson = JSON.stringify(filteredDocuments);

              localStorage.setItem("docs", documentsJson);
              localStorage.setItem("modelId", modelId);
              localStorage.setItem("instanceId", instanceId);
              localStorage.setItem("tabOpen", true);

              const newWindow = window.open("/User/DocView", "_blank");

              const intervalId = setInterval(() => {
                if (newWindow.closed) {
                  clearInterval(intervalId);
                  localStorage.removeItem("tabOpen");
                  window.location.reload();
                }
              }, 1000);
            }}
          >
            Qualifier
          </button>
          <button
            className='btn btn-danger'
            onClick={() => navigate("/User/plan01Lots")}
          >
            {" "}
            Retour{" "}
          </button>
          <button className='btn btn-primary' onClick={handleUpdateSelected}>
            Mettre à jour sélectionnés
          </button>{" "}
          <select
            id='itemsPerPage'
            value={itemsPerPage}
            onChange={handleItemsPerPageChange}
          >
            <option value={10}>10</option>
            <option value={20}>20</option>
            <option value={50}>50</option>
          </select>
          {/* Bouton pour mettre à jour les documents sélectionnés */}
          {Array.from({ length: pageCount }, (_, i) => i + 1).map((number) => (
            <button
              key={number}
              className={`btn btn-outline-secondary ${
                currentPage === number ? "disabled" : ""
              }`}
              onClick={() => setCurrentPage(number)}
            >
              {number}
            </button>
          ))}
        </div>
      </div>

      {modalIsOpen && (
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          contentLabel='Upload Document Modal'
        >
          <h2>Upload Document</h2>
          <input type='file' onChange={handleFileChange} />
          <button onClick={handleUpload}>Upload</button>
          <button onClick={closeModal}>Close</button>
        </Modal>
      )}
      {}
    </>
  );
};

export default Plan01Doc;
