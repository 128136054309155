/** @format */

import React, { useState } from "react";
import { Doughnut } from "react-chartjs-2";
import { Chart as ChartJS } from "chart.js/auto";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import "bootstrap/dist/css/bootstrap.min.css";

const DocStat = ({ lots }) => {
  const [isCollapsed, setIsCollapsed] = useState(true);

  const toggleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };

  // Calcul des statistiques de statuts pour le Doughnut chart
  const statusCounts = lots.reduce((acc, lot) => {
    const status = lot.Status;
    acc[status] = (acc[status] || 0) + 1;
    return acc;
  }, {});
  const options = {
    plugins: {
      legend: {
        position: 'right',
        labels: {
          usePointStyle: true, // Permettre l'utilisation de points à la place des carrés dans la légende
          textAlign: 'start', // Aligner le texte de début
          font: {
            size: 14,
          },
          padding: 15,
        },
      },
    },
  };
  

  const backgroundColors = [
    "rgba(255, 99, 132, 0.2)",
    "rgba(54, 162, 235, 0.2)",
    "rgba(255, 206, 86, 0.2)",
    "rgba(75, 192, 192, 0.2)",
    "rgba(153, 102, 255, 0.2)",
    "rgba(255, 159, 64, 0.2)",
  ];

  const borderColors = [
    "rgba(255, 99, 132, 1)",
    "rgba(54, 162, 235, 1)",
    "rgba(255, 206, 86, 1)",
    "rgba(75, 192, 192, 1)",
    "rgba(153, 102, 255, 1)",
    "rgba(255, 159, 64, 1)",
  ];

  const chartData = {
    labels: Object.keys(statusCounts),
    datasets: [
      {
        label: "Répartition des statuts",
        data: Object.values(statusCounts),
        backgroundColor: backgroundColors,
        borderColor: borderColors,
        borderWidth: 1,
      },
    ],
  };

  // Calcul des statistiques des documents, des plans et de leur complexité
  const stats = lots.reduce(
    (acc, lot) => {
      if (lot.TypePlan === "DOC") {
        acc.totalDocumentsOCR++;
      } else if (lot.TypePlan !== "DOC" && !lot.Complexite) {
        acc.plansAQualifier++;
      } else if (lot.TypePlan !== "DOC") {
        switch (lot.Complexite) {
          case "Complexe":
            acc.totalPlansComplexes++;
            break;
          case "Moyen":
            acc.totalPlansMoyens++;
            break;
          case "Simple":
            acc.totalPlansSimples++;
            break;
          default:
            break;
        }
      }

      return acc;
    },
    {
      totalDocumentsOCR: 0,
      totalPlansComplexes: 0,
      totalPlansMoyens: 0,
      totalPlansSimples: 0,
      plansAQualifier: 0,
    }
  );

  return (
    <div className='container mt-4 collapsible-section'>
      <div className='titre-stat' onClick={toggleCollapse}>
        Statistiques <FontAwesomeIcon icon={isCollapsed ? faChevronDown : faChevronUp} />
      </div>
      {!isCollapsed && (
        <div className='row'>
          <div className='col-md-6'>
            <div
              className='chart-container'
              style={{ width: "100%", height: "300px" }}
            >
              <Doughnut data={chartData} options={options} />
            </div>
          </div>
          <div className='col-md-6'>
            <div
              className='table-responsive'
              style={{
                height: "210px",
                lineHeight: "0.6",
                borderRadius: "10px",
              }}
            >
              <table className='table table-striped'>
                <thead>
                  <tr>
                    <th>Catégories</th>
                    <th>Total</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Documents OCR</td>
                    <td>{stats.totalDocumentsOCR}</td>
                  </tr>
                  <tr>
                    <td>Plans complexes</td>
                    <td>{stats.totalPlansComplexes}</td>
                  </tr>
                  <tr>
                    <td>Plans moyens</td>
                    <td>{stats.totalPlansMoyens}</td>
                  </tr>
                  <tr>
                    <td>Plans simples</td>
                    <td>{stats.totalPlansSimples}</td>
                  </tr>
                  <tr>
                    <td>Plans à qualifier</td>
                    <td>{stats.plansAQualifier}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      )}
      <br></br>
    </div>
  );
};

export default DocStat;
