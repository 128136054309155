/** @format */

import process from "process";
const API_BASE_URL = process.env.REACT_APP_API_URL;
//const token = localStorage.getItem("idToken");
//localStorage.getItem("idToken"),
export async function createInstanceAndDocs(
  instanceName,
  modelId,
  files,
  token,
  json = null
) {
  try {
    const numberOfDocs = files.length; // Calcul du nombre de documents à créer
    const createInstanceResponse = await createInstance(
      instanceName,
      modelId,
      numberOfDocs,
      token
    );
    if (createInstanceResponse.statusCode === 200) {
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        const fileName = file.name;
        const createDocsResponse = await createDocs(
          createInstanceResponse.message.InstanceId,
          fileName,
          token,
          json
        );
        if (createDocsResponse.statusCode === 200) {
          const presignedUrl = createDocsResponse.message.PresignedURL;
          await uploadDoc(file, presignedUrl, token).then((response) => {
            console.log(response);
            return response;
          });
        }
      }
    }
  } catch (error) {
    throw new Error(error);
  }
}

export async function createInstance(
  instanceName,
  modelId,
  exeptedDocs,
  token
) {
  var instance = instanceName;
  var raw = JSON.stringify({
    ModelId: modelId,
    InstanceName: instance,
    ExpectedDocs: Number(exeptedDocs),
    Options: {
      OutputAs: "json",
    },
  });

  try {
    const response = await fetch(API_BASE_URL + "/process/create", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      body: raw,
    });
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    const data = await response.json();
    console.log(data);
    return data;
  } catch (error) {
    throw new Error(error);
  }
}

async function createDocs(instanceId, fileName, token, json = "") {
  const jsonData = json ? JSON.parse(json) : {};
  try {
    const response = await fetch(API_BASE_URL + "/document/s3upload", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      body: JSON.stringify({
        InstanceId: instanceId,
        FileName: fileName,
        FileType: "application/pdf",
        InputData: jsonData,
      }),
    });
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    const data = await response.json();
    return data;
  } catch (error) {
    throw new Error(error);
  }
}

async function uploadDoc(file, signedUrl, token) {
  var mypresignedurl = signedUrl;
  var requestOptions = {
    method: "PUT",
    body: file,
    headers: {
      "Content-Type": "application/pdf",
      "Content-Length": file.length,
    },
  };

  try {
    const response = await fetch(mypresignedurl, requestOptions);
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.statusCode}`);
    }
  } catch (error) {
    throw new Error(error);
  }
}
