import { React, useState, useEffect } from "react";
import {
  Navbar,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  Collapse,
  NavbarToggler,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserCircle } from "@fortawesome/free-solid-svg-icons";
import logo_ah from "../../IMG/media/LOGOTYPES/MyActivHub/Web/PNG/logoType_light.png";
import IRM from "../../IMG/IMF.png";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import './nav.css'

export default function NavBar() {
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate(); 
  const toggle = () => {
    setIsOpen(!isOpen);
  };

  const handleLogout = () => {
    localStorage.clear();
    Cookies.remove("Token");
    navigate("/");
  };

  const Nom = Cookies.get("Username").toLowerCase();
  
  return (
    <div className="nav-container">
      <Navbar dark className="custom-navbar" style={{ backgroundColor: "#235073", marginBottom: 0 }}>
        <NavbarToggler onClick={toggle} className="mr-2" />
        <div className="user-info ml-auto d-flex align-items-center">
          <img
            src={Nom.includes('iron mountain') ? IRM : logo_ah}
            alt="Logo"
            className="logo"
            style={{ height: "30px", width: "auto", marginRight: "10px" }}
          />
          <UncontrolledDropdown setActiveFromChild>
            <DropdownToggle tag="a" className="nav-link d-flex align-items-center" caret>
              <FontAwesomeIcon
                icon={faUserCircle}
                style={{ fontSize: "24px", marginRight: "8px", color:'white' }}
              />
              <font style={{color:'white'}}>{Nom}</font>
            </DropdownToggle>
            <DropdownMenu end>
              <DropdownItem onClick={handleLogout}>Déconnexion</DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </div>
        <div className={`sidebar ${isOpen ? "open" : ""}`}>
          <Nav navbar vertical>
            <NavItem>
              <NavLink className="text-white" href="/User/">
                Accueil
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink className="text-white" href="/User/Import">
                Import
              </NavLink>
            </NavItem>
          </Nav>
        </div>
      </Navbar>
    </div>
  );
}