/** @format */

import process from "process";
import Cookies from "js-cookie";
const apiURL = process.env.REACT_APP_API_URL;

export async function loginUser(username, password) {
  const loginApiUrl = `${apiURL}/authentication/login`;
  const userInfoApiUrl = `${apiURL}/user/get`;
  const loginData = {
    username: username,
    password: password,
  };

  try {
    const loginResponse = await fetch(loginApiUrl, {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(loginData),
    });
    console.log(loginResponse);
    if (!loginResponse.ok) {
      throw new Error(
        "Login request failed with status " + loginResponse.status
      );
    } else {
      const loginResult = await loginResponse.json();
      console.log(loginResult);
      Cookies.set("Token", loginResult.message.idToken, {
        secure: true,
      });

      const idToken = loginResult.message.idToken;
      const userInfoResponse = await fetch(userInfoApiUrl, {
        method: "GET",
        headers: {
          Authorization: idToken,
        },
      });

      const userInfo = await userInfoResponse.json();

      console.log(userInfo);
      Cookies.set("Username", userInfo.message.name);
      return { userInfo: userInfo.message.client, idToken: idToken };
    }
  } catch (error) {
    console.log(error);

    throw new Error(error);
  }
}
