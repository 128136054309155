/** @format */

import React, { useRef, useState, useEffect, useCallback } from "react";
import { createInstanceAndDocs } from "../../../API/uploadToS3";
import { getModel } from "../../../API/modelList";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import NavBar from "../../Navbar/Navbar";
import {
  faFile,
  faCheck,
  faTrash,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import CodeMirror from "@uiw/react-codemirror";
import { dracula } from "@uiw/codemirror-theme-dracula";
import Cookies from "js-cookie";
import { json } from "@codemirror/lang-json";
import { Modal, Spinner, Alert, Button, Card, Form } from "react-bootstrap";

export default function Import() {
  const fileInputRef = useRef(null);
  const [instanceName, setInstanceName] = useState("");
  const [fileNumber, setFileNumber] = useState(null);
  const [error, setError] = useState(null);
  const [step, setStep] = useState(1);
  const [modelList, setModelList] = useState([]);
  const [selectedModel, setSelectedModel] = useState("All");
  const [showJSONEditor, setShowJSONEditor] = useState(false);
  const [value, setValue] = useState("");
  const idToken = Cookies.get("Token");
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [droppedFileNames, setDroppedFileNames] = useState([]);
  const [loading, setLoading] = useState(false);
  const [apiResponse, setApiResponse] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    getModel(idToken)
      .then((data) => {
        setModelList(data);
      })
      .catch((error) => {
        console.error("Error fetching model list:", error);
      });
  }, [idToken]);

  const handleFileSelect = () => {
    fileInputRef.current.click();
  };

  const handleFileUpload = async (e) => {
    const files = e.target.files;
    const newSelectedFiles = Array.from(files);
    setSelectedFiles(newSelectedFiles);

    const fileNames = newSelectedFiles.map((file) => file.name);
    setDroppedFileNames(fileNames);
  };

  const handleModelChange = (e) => {
    setSelectedModel(e.target.value);
  };

  const onChange = useCallback((val) => {
    JSON.stringify(val);
    setValue(val);
  }, []);

  const handleNext = () => {
    if (step === 1) {
      if (selectedModel === "All") {
        setError("Please select a model.");
      } else {
        setStep(2);
        setError("");
      }
    } else if (step === 2) {
      if (!instanceName) {
        setError("Please enter an instance name.");
      } else {
        setStep(3);
        setError("");
      }
    } else if (step === 3) {
      if (!fileNumber || isNaN(fileNumber)) {
        setError("Please enter a valid number of files.");
      } else {
        setStep(4);
        setError("");
      }
    }
  };

  const handleDrop = async (e) => {
    e.preventDefault();
    const files = e.dataTransfer.files;
    if (files.length > 0) {
      const newSelectedFiles = Array.from(files);
      setSelectedFiles(newSelectedFiles);

      const fileNames = newSelectedFiles.map((file) => file.name);
      setDroppedFileNames(fileNames);
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleFileDelete = () => {
    setSelectedFiles([]);
    setDroppedFileNames([]);
  };

  const handleOpenJSONEditor = () => {
    setShowJSONEditor(true);
  };

  const handleCloseJSONEditor = () => {
    setShowJSONEditor(false);
  };

  const handleFileSubmit = async () => {
    if (!instanceName || !selectedModel || !droppedFileNames) {
      setError("Please fill in all the fields.");
      return;
    }

    setLoading(true);

    try {
      const response = await createInstanceAndDocs(
        instanceName,
        selectedModel,
        selectedFiles,
        idToken,
        value
      );

      setApiResponse(response);
      setLoading(false);
      console.log(response);
      setStep(0);
      setInstanceName("");
      setFileNumber(null);
      setSelectedModel("All");
    } catch (error) {
      console.error(error);
      setLoading(false);
      setStep(-1);
    }
  };

  const showStepErr = step === -1;
  const showStep0 = step === 0;
  const showStep1 = step >= 1;
  const showStep2 = step >= 2;
  const showStep3 = step >= 3;

  return (
    <>
      <NavBar />
      <div className='container' style={{ backgroundColor: "#f2f0f0", padding: "20px 20px 50px 20px" }}>
        <div onDrop={handleDrop} onDragOver={handleDragOver}>
          {loading && (
            <Modal show={true} centered>
              <Modal.Body className="text-center">
                <Spinner animation="border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
                <h4>Traitement en cours...</h4>
              </Modal.Body>
            </Modal>
          )}
          {!loading && (
            <>
              {showStepErr && (
                <Card className='text-center' style={{ width: "600px", margin: "20px auto", borderRadius: "2px", borderColor: "#3c6382" }}>
                  <Card.Body>
                    Erreur dans le traitement, veuillez réessayer
                    <Button className='btn-success' onClick={() => navigate("/User/")}>
                      Retour instance
                    </Button>
                    <Button className='btn-success' onClick={() => setStep(1)}>
                      Ajouter une instance
                    </Button>
                  </Card.Body>
                </Card>
              )}
              {showStep0 && (
                <Alert variant="success" style={{ width: "600px", margin: "20px auto" }}>
                  Traitement effectué avec succès
                  <div className="d-flex justify-content-between">
                    <Button className='btn-success' onClick={() => navigate("/User/")}>
                      Retour instance
                    </Button>
                    <Button className='btn-success' onClick={() => setStep(1)}>
                      Ajouter une autre instance
                    </Button>
                  </div>
                </Alert>
              )}
              {showStep1 && (
                <Card className='text-center' style={{ width: "600px", margin: "20px auto", borderRadius: "2px", borderColor: "#3c6382" }}>
                  <Card.Body>
                    <Card.Title style={{ color: "#3c6382" }}>Choisissez votre modèle</Card.Title>
                    <Form.Select style={{ width: "300px", margin: "0 auto 30px auto", borderColor: "#3c6382", color: "#3c6382" }} value={selectedModel} onChange={handleModelChange}>
                      <option value='All'>Choix du modèle</option>
                      {modelList.map((model) => (
                        <option key={model.ModelId} value={model.ModelId}>{model.ModelName}</option>
                      ))}
                    </Form.Select>
                    <Button className='btn' style={{ backgroundColor: "#5eb66e", color: "#fff", width: "150px" }} onClick={handleNext}>
                      Suivant <FontAwesomeIcon icon={faArrowRight} style={{ marginLeft: "10px" }} />
                    </Button>
                  </Card.Body>
                </Card>
              )}
              {showStep2 && (
                <Card className='text-center' style={{ width: "600px", margin: "20px auto", borderRadius: "2px", borderColor: "#3c6382" }}>
                  <Card.Body>
                    <Card.Title style={{ color: "#3c6382" }}>Entrez un nom d'instance</Card.Title>
                    <Form.Control
                      style={{ width: "300px", margin: "0 auto 30px auto", borderColor: "#3c6382", color: "#3c6382" }}
                      type='text'
                      placeholder="Entrez un nom d'instance"
                      value={instanceName}
                      onChange={(e) => setInstanceName(e.target.value)}
                    />
                    <Button className='btn' style={{ backgroundColor: "#5eb66e", color: "#fff", width: "150px" }} onClick={handleNext}>
                      Suivant <FontAwesomeIcon icon={faArrowRight} style={{ marginLeft: "10px" }} />
                    </Button>
                  </Card.Body>
                </Card>
              )}
              {showStep3 && (
                <Card className='text-center' style={{ width: "600px", margin: "20px auto", borderRadius: "2px", borderColor: "#3c6382" }}>
                  <Card.Body>
                    <Card.Title style={{ color: "#3c6382" }}>Ajoutez un / des fichier(s)</Card.Title>
                    <ul style={{ color: "#3c6382", marginBottom: "20px" }}>
                      Fichier(s) choisis : {droppedFileNames.map((fileName) => <li key={fileName}>{fileName}</li>)}
                    </ul>
                    <input type='file' ref={fileInputRef} style={{ display: "none" }} onChange={handleFileUpload} multiple />
                    <div className="d-flex justify-content-center">
                      <Button className='btn btn-primary' onClick={handleFileSelect} style={{ marginRight: "10px" }}>
                        <FontAwesomeIcon icon={faFile} /> Choix fichier
                      </Button>
                      <Button className='btn btn-success' onClick={handleFileSubmit} style={{ marginRight: "10px" }}>
                        <FontAwesomeIcon icon={faCheck} /> UP
                      </Button>
                      <Button className='btn btn-danger' onClick={handleFileDelete}>
                        <FontAwesomeIcon icon={faTrash} /> Suppr
                      </Button>
                    </div>
                    <div className="d-flex justify-content-center" style={{ marginTop: "10px" }}>
                      <Button className='btn btn-warning' onClick={handleOpenJSONEditor}>
                        <FontAwesomeIcon icon={faPlus} /> JSON
                      </Button>
                    </div>
                    {showJSONEditor && (
                      <>
                        <Card.Title style={{ color: "#3c6382", marginTop: "20px" }}>Ajout JSON</Card.Title>
                        <div style={{ width: "400px", margin: "0 auto" }}>
                          <CodeMirror value={value} height='700px' theme={dracula} extensions={[json({ jsx: true })]} onChange={onChange} />
                        </div>
                        <Button className='btn btn-primary' onClick={handleCloseJSONEditor} style={{ marginTop: "10px" }}>
                          Masquer
                        </Button>
                      </>
                    )}
                  </Card.Body>
                </Card>
              )}
              {error && <Alert variant="danger" style={{ width: "600px", margin: "20px auto" }}>{error}</Alert>}
            </>
          )}
        </div>
      </div>
    </>
  );
}