/** @format */
import apiConfig from "../config/_config"; // api.js
import process from "process";
const API_BASE_URL = process.env.REACT_APP_API_URL; // Remplacez par l'URL de base de votre API

export async function getDoc(token, instanceId) {
  const apiUrl = `${API_BASE_URL}/document/list/plans`;
  try {
    const response = await fetch(apiUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      body: JSON.stringify({
        InstanceId: instanceId,
      }),
    });
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    const data = await response.json();
    console.log("doclist", JSON.stringify(data));
    return data.message.list;
  } catch (error) {
    throw new Error(error);
  }
}
