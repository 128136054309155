import process from "process";
const API_BASE_URL =process.env.REACT_APP_API_URL; // Remplacez par l'URL de base de votre API

/**
 * Met à jour les données pour une instance spécifique.
 * @param {string} token Le token d'authentification
 * @param {string} instanceId L'ID de l'instance à mettre à jour
 * @param {Object} updateData Les données à mettre à jour
 * @returns {Promise<Object>} Le résultat de l'appel d'API
 */
export async function docAttachment(token,InstanceId, DocumentId, filetype, model) {
  console.log(InstanceId, DocumentId, filetype, model)
  const url = `${API_BASE_URL}/document/attachment/download`;
  try {
    const response = await fetch(url, {
      method: 'POST', 
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token,
      },
      body: JSON.stringify(
        {
            "DocumentId" : DocumentId,
            "InstanceId" : InstanceId,
            "ModelId" : model,
            "AttachmentType" : filetype

    }),
    });

    if (!response.ok) {
      alert('Mise à jour échoué pour le doc' + DocumentId )
      throw new Error(`Erreur API: ${response.status} ${response.statusText}`);
    }

    
    const data = await response.json(); // Attendre la résolution de la promesse
    if (data && data.message && data.message.PresignedURL) {
      const presignedUrl = data.message.PresignedURL;
      //window.open(presignedUrl, '_blank');
      downloadFile(presignedUrl);
    } else {
      console.error('PresignedURL non trouvé dans les données.');
    }
  } catch (error) {
    console.error("Erreur lors de la mise à jour de l'instance:", error);
    throw error; // Rethrow l'erreur pour la gestion d'erreur en amont
  }
}
async function downloadFile(url) {
  try {
    const response = await fetch(url);
    if (!response.ok) {
      throw new Error(`Erreur lors du téléchargement: ${response.statusText}`);
    }
    const blob = await response.blob();
    
    let filename = url.split('/').pop().split('?')[0]; // Extrait le nom de fichier du chemin de l'URL
    
    


    const downloadUrl = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = downloadUrl;
    link.setAttribute('download', filename);

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    window.URL.revokeObjectURL(downloadUrl); // Libère l'URL objet après le téléchargement
  } catch (error) {
    console.error("Erreur lors du téléchargement du fichier:", error);
  }
}
