/** @format */

import process from "process";

const apiURL = process.env.REACT_APP_API_URL;
//const token = localStorage.getItem("idToken");
//localStorage.getItem("idToken"),
export async function attachZip(
  instanceId,
  docId,
  modelId,
  files,
  token,
  fileExtension,
  json = null
) {
  try {
    const createDocsResponse = await attachementDoc(
      instanceId,
      docId,
      token,
      modelId,
      fileExtension
    );
    if (createDocsResponse.statusCode === 200) {
      const presignedUrl = createDocsResponse.message.PresignedURL;
      await uploadDoc(files, presignedUrl).then((response) => {
        console.log(response);
        return response;
      });
    }
  } catch (error) {
    throw new Error(error);
  }
}

async function attachementDoc(
  instanceId,
  docId,
  token,
  modelId,
  fileExtension
) {
  try {
    const response = await fetch(apiURL + "/document/attachment/upload", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      body: JSON.stringify({
        DocumentId: docId,
        InstanceId: instanceId,
        ModelId: modelId,
        AttachmentType: fileExtension,
      }),
    });
    console.log("extension sent is", fileExtension);
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    const data = await response.json();
    console.log(data);
    return data;
  } catch (error) {
    throw new Error(error);
  }
}

async function uploadDoc(file, signedUrl) {
  var mypresignedurl = signedUrl;
  var requestOptions = {
    method: "PUT",
    body: file,
    headers: {
      "Content-Type": "application/pdf",
      "Content-Length": file.length,
    },
  };

  try {
    const response = await fetch(mypresignedurl, requestOptions);
    console.log(response);
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.statusCode}`);
    }
  } catch (error) {
    throw new Error(error);
  }
}
