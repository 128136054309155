// DocQuery.js

import process from "process";
const API_BASE_URL =process.env.REACT_APP_API_URL; // Remplacez par l'URL de base de votre API

/**
 * Met à jour les données pour une instance spécifique.
 * @param {string} token Le token d'authentification
 * @param {string} instanceId L'ID de l'instance à mettre à jour
 * @param {Object} updateData Les données à mettre à jour
 * @returns {Promise<Object>} Le résultat de l'appel d'API
 */
export async function docUpdate(token,InstanceId, DocumentId, sComplexite, prio, isValid,status) {

console.log('valid =', isValid)
  const url = `${API_BASE_URL}/document/update`;
  try {
    const response = await fetch(url, {
      method: 'POST', 
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token,
      },
      body: JSON.stringify(
        {
            "DocumentId" : DocumentId,
            "InstanceId" : InstanceId,            
            "FieldList" : {
                "sComplexite" : sComplexite,
                "bValid" : isValid,
                "dPriorite" : prio,
                "sStatus":status
            
        }
    }),
    });

    if (!response.ok) {
      alert('Mise à jour échoué pour le doc' + DocumentId )
      throw new Error(`Erreur API: ${response.status} ${response.statusText}`);
    }

console.log(response)
    return await response.json(); // Ou retourner une valeur spécifique selon les besoins
  } catch (error) {
    console.error("Erreur lors de la mise à jour de l'instance:", error);
    throw error; // Rethrow l'erreur pour la gestion d'erreur en amont
  }
}
