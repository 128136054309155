/** @format */

import process from "process";
import { ToastContainer, toast } from "react-toastify";
const API_BASE_URL = process.env.REACT_APP_API_URL;
/**
 * Met à jour les données pour une instance spécifique.
 * @param {string} token Le token d'authentification
 * @param {string} instanceId L'ID de l'instance à mettre à jour
 * @param {Object} updateData Les données à mettre à jour
 * @returns {Promise<Object>} Le résultat de l'appel d'API
 */
export async function updateInstanceData(
  token,
  instanceId,
  status,
  dDateRemise
) {
  //const url =  'https://api-imfdev.myactivhub.net/model/list';
  const url = API_BASE_URL + "/process/update/";
  console.log("URL", url);
  console.log("Status",status);
  console.log("Date Remise",dDateRemise);
  try {
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      body: JSON.stringify({
        InstanceId: instanceId,
        FieldList: {
          dDateRemise: dDateRemise,
          sStatus: status,
        },
      }),
    });

    if (!response.ok) {
      alert("Mise à jour echoué");
      throw new Error(`Erreur API: ${response.status} ${response.statusText}`);
    } else {
      console.log("Instance mise à jour avec succès!");
      toast.success("Instance mise à jour avec succès!");
    }

    return await response.json(); // Ou retourner une valeur spécifique selon les besoins
  } catch (error) {
    console.error("Erreur lors de la mise à jour de l'instance:", error);
    throw error; // Rethrow l'erreur pour la gestion d'erreur en amont
  }
}
